<template lang="pug">
.brand-wrapper.brand-create-goal
  .container-fluid(v-if="campaign")
    .row
      .col-7
        .font-size-0--875.text-color-mild-gray {{ campaign.domain }}
        .d-flex-1.align-items-center
          om-heading(h1) {{ campaign.name }}
    .row.d-flex.mt-6
      .col-6.create-goal-section
        om-heading.mb-1(h3) {{ $t('conversionGoals.dcFlow.createGoal') }}
        om-body-text.mt-5.description(bt400sm) {{ $t('conversionGoals.dcFlow.description') }}
        .d-flex.flex-column.mt-6.col-11.pl-0.pr-5
          om-select-card(
            :title="$t('conversionGoals.dcFlow.createGoalAction')"
            :arrow="false"
            icon="create"
            small
            :backgroundOff="true"
            @click="openCreateGoalModal(domainId)"
          )
          om-select-cards.custom-goals(
            v-model="chosenGoal"
            v-if="customGoals.length"
            :options="customGoals"
          )
          om-select-cards(
            v-model="chosenGoal"
            v-if="isShopifyOrShoprenter"
            :options="defaultGoals"
          )
      .d-flex.col-6.mt-6.justify-content-end.align-items-start.flex-row
        description
  create-edit-goal(@goals:createGoal="upsertGoal")
</template>
<script>
  import GET_CAMPAIGN from '@/graphql/GetCampaign.gql';
  import GET_GOALS from '@/graphql/GetGoals.gql';
  import { mapGetters } from 'vuex';
  import goalRules from '@/mixins/goalRules';
  import Description from '@/components/Goals/DescriptionModule.vue';
  import CreateEditGoal from '@/components/Modals/CreateEditGoal.vue';

  export default {
    components: { Description, CreateEditGoal },
    mixins: [goalRules],

    data() {
      return {
        campaign: null,
        goals: [],
        domain: null,
        chosenGoal: null,
      };
    },
    computed: {
      ...mapGetters(['domains', 'isShopifyDomain', 'isShoprenterDomain']),
      domainId() {
        return this.domains.find((domain) => domain.domain === this.domain)?._id;
      },
      defaultGoals() {
        return this.goals
          .filter((goal) => goal.default)
          .map((goal) => {
            const { _id, name, default: isDefault, rules } = goal;
            return {
              key: this.$t(`conversionGoals.dcFlow.${name}Title`),
              details: this.getRuleText({ name, rules, isDefault }),
              icon: name === 'purchase' ? 'purchase' : 'stop-cart-abandonment',
              value: _id,
              arrow: false,
              small: true,
              backgroundOff: true,
            };
          });
      },
      customGoals() {
        return this.goals
          .filter((goal) => !goal.default)
          .map((goal) => {
            const { name, default: isDefault, rules } = goal;
            return {
              key: goal.name,
              details: this.getRuleText({ name, rules, isDefault }),
              icon: 'retarget',
              value: goal._id,
              arrow: false,
              small: true,
              backgroundOff: true,
            };
          });
      },
      isShopifyOrShoprenter() {
        return this.isShopifyDomain(this.domain) || this.isShoprenterDomain(this.domain);
      },
    },
    watch: {
      async domainId() {
        if (this.domainId) {
          this.getGoals();
        }
      },
    },

    async created() {
      this.$bus.$on('saveDCGoal', this.saveGoal);

      const { id } = this.$route.params;
      this.campaignId = id;

      const {
        data: { campaign },
      } = await this.$apollo.query({
        query: GET_CAMPAIGN,
        variables: {
          campaignId: this.campaignId,
        },
      });
      this.campaign = campaign;
      this.domain = campaign?.domain;
      this.fetchCampaigns([this.domainId]);
    },

    beforeDestroy() {
      this.$bus.$off('saveDCGoal', this.saveGoal);
    },

    methods: {
      saveGoal({ redirect }) {
        this.saveDefaultGoalToLS(
          this.chosenGoal,
          this.campaign._id,
          this.DEFAULT_GOAL_BY_CAMPAIGN_KEY,
        );
        this.createMaterializedViewIfNecessary(this.chosenGoal, this.goals, this.domainId);
        this.$router.push(redirect);
      },
      async getGoals() {
        const {
          data: { goals },
        } = await this.$apollo.query({
          query: GET_GOALS,
          variables: {
            domainId: this.domainId,
            includeRules: true,
          },
        });
        this.goals = goals.goals;
      },
    },
  };
</script>
<style lang="sass">
  .navbar.brand-custom-navbar
    .container
      max-width: 100%!important
    .right-part
      justify-content: flex-end
</style>
<style lang="sass" scoped>
  @import "@/sass/variables/_colors.sass"

  .brand-create-goal
    min-height: calc(100vh - 3.75rem)
    padding-bottom: 5rem
    background-color: white
    padding-top: 2rem

  .create-goal-section
    margin-top: 42px

  .description
    color: $om-gray-700
    max-width: 440px

  .custom-goals
    margin-bottom: 9px
</style>
